<template>
  <div class="mobile-menu">
    <div class="mobile-menu-header">
      <div class="mobile-menu__user">
        <div class="mobile-menu__user-name">{{ authUserName }}</div>
        <div class="d-flex">
          <router-link :to="{ name: 'AgencyProfile' }">
            <settings-svg />
            <span>Profile</span>
          </router-link>

          <router-link :to="{ name: 'SignOut', params: { type: 'agency' } }">
            <log-out-svg />
            <span>Sign out</span>
          </router-link>
        </div>
      </div>
      <div class="mobile-menu__close" @click="closeMobileMenu">
        <cross-svg />
      </div>
    </div>
    <div class="mobile-menu-body">
      <div class="mobile-menu-wrapper">
        <router-link
          :to="{ name: 'MyCandidates' }"
          class="mobile-menu__link"
        >
          My Candidates
        </router-link>
        <router-link
          :to="{ name: 'SubmitCandidate' }"
          class="mobile-menu__link"
        >
          Submit Candidate
        </router-link>
        <router-link
          :to="{ name: 'RequestedCandidates' }"
          class="mobile-menu__link"
        >
          Requested Candidates
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import SettingsSvg from '@/components/svg/SettingsSvg.vue';
import LogOutSvg from '@/components/svg/LogOutSvg.vue';
import CrossSvg from '@/components/svg/CrossSvg.vue';

import { mapGetters } from 'vuex';

export default {
  name: 'AgencyMobileMenu',
  components: {
    SettingsSvg,
    LogOutSvg,
    CrossSvg,
  },
  computed: {
    ...mapGetters({
      authUserName: 'auth/getAuthUserName',
    }),
  },
  methods: {
    closeMobileMenu() {
      this.$store.commit('setMobileMenuStatus', false);
    },
  },
};
</script>
